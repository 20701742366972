import React, { useEffect } from "react";
import { useLazyLoadQuery, useMutation } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { useTypedDispatch } from "../../redux/redux-store";
import { CurrentUser_Query } from "@generated/CurrentUser_Query.graphql";
import { setCurrentUser } from "../../redux/slices/current-user.slice";
import { useDialogLogic } from "../../hooks/useDialogLogic";
import { LMS_WEBAPP_CONFIG_IMPL } from "../../../infecto-lms-webapp-impl/config";
import { CurrentUser_AcceptPrivacyMutation } from "@generated/CurrentUser_AcceptPrivacyMutation.graphql";
import { logout } from "../../redux/slices/auth.slice";

const QUERY = graphql`
	query CurrentUser_Query($brandId: ID!) {
		Viewer {
			Auth {
				currentUser {
					permissionsInAccount
					user {
						extension {
							... on InfectopharmUserExtensionImpl {
								country
								globalUserStatus
							}
						}
					}
				}
			}

			Privacy {
				UnacceptedPrivacy(brandId: $brandId) {
					id
					content
				}
			}
		}
	}
`;

const ACCEPT_PRIVACY_MUTATION = graphql`
	mutation CurrentUser_AcceptPrivacyMutation($input: AcceptPrivacyInput!) {
		Viewer {
			Privacy {
				acceptPrivacy(input: $input) {
					clientMutationId
				}
			}
		}
	}
`;

interface OwnProps {
	children: any;
}

export const CurrentUser = ({ children }: OwnProps) => {
	const dispatch = useTypedDispatch();
	const { privacyDialog, showPrivacyDialog, isAcceptingNewPrivacy } = useDialogLogic();
	const searchParams = new URLSearchParams(window.location.search);
	const adminLogin = searchParams.get("adminLogin") === "true";

	const currentUserQuery = useLazyLoadQuery<CurrentUser_Query>(QUERY, {
		brandId: LMS_WEBAPP_CONFIG_IMPL.brandId,
	});

	const [acceptPrivacy] = useMutation<CurrentUser_AcceptPrivacyMutation>(ACCEPT_PRIVACY_MUTATION);

	useEffect(() => {
		if (currentUserQuery.Viewer.Auth.currentUser) {
			dispatch(setCurrentUser(currentUserQuery.Viewer.Auth.currentUser.user.extension));
		}

		if (currentUserQuery.Viewer.Auth.currentUser) {
			localStorage.setItem(
				"currentuser-country",
				JSON.stringify(currentUserQuery.Viewer.Auth.currentUser.user.extension.country),
			);
		}
		const isUserStatusDocCheck =
			currentUserQuery.Viewer.Auth.currentUser?.user.extension.globalUserStatus === "doccheck";
		const userPermissions = currentUserQuery.Viewer.Auth.currentUser?.permissionsInAccount;
		const isAdmin =
			userPermissions?.includes("AccountPermission_System_Root") &&
			userPermissions?.includes("UserInAccountPermission_System_Owner");

		if (
			currentUserQuery.Viewer.Privacy.UnacceptedPrivacy &&
			!isUserStatusDocCheck &&
			!isAdmin &&
			!adminLogin
		) {
			showPrivacyDialog({
				title: "Datenschutz",
				content: `Wir haben unsere <a href='${LMS_WEBAPP_CONFIG_IMPL.privacyLink}' target='_blank'  style="color: ${LMS_WEBAPP_CONFIG_IMPL.brandColor}"; >Datenschutzbestimmungen</a> aktualisiert.<br> Damit Ihr Nutzer-Account weiterhin aktiv bleibt, benötigen wir einmalig Ihre Zustimmung zu den neuen Bestimmungen.`,
				affirmativeText: "Datenschutz Akzeptieren",
				negativeText: "Abbrechen & Abmelden",
				dialogCallback: (result) => {
					if (result === "Accept") {
						isAcceptingNewPrivacy(true);
						acceptPrivacy({
							variables: {
								input: {
									privacyId: currentUserQuery.Viewer.Privacy.UnacceptedPrivacy?.id as string,
									acceptanceText: "Datenschutz Akzeptieren",
								},
							},
							onCompleted: () => {
								isAcceptingNewPrivacy(false);
							},
						});
					} else if (result === "Deny") {
						dispatch(logout());
					}
				},
				dismissible: false,
			});
		}
		// eslint-disable-next-line
  }, [currentUserQuery]);

	return (
		<>
			{children}
			{privacyDialog}
		</>
	);
};
